import {apiRequest, GET_AUTH_USER} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {getToken} from '../../../../_shared/utils';
import {GET, Services, ServicesURLs} from '../../../../_shared/constants';

export const getAuthUser: Middleware<unknown, RootState> =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action);
        const {type} = action;
        if (type === GET_AUTH_USER.START) {
          const token = getToken();
          const {key} = action.meta;
          console.log('token :::: ', token);
          dispatch(
            apiRequest({
              service: Services.auth,
              method: GET,
              url: ServicesURLs.getLoggedInUser,
              key: key ?? 'getAuthUser',
              onError: GET_AUTH_USER.ERROR,
              onSuccess: (data: any) => {
                dispatch({type: GET_AUTH_USER.SUCCESS, payload: data});
              }
            })
          );
        }
        if (type === GET_AUTH_USER.ERROR) {
          console.log(
            `${process.env.REACT_APP_AUTH_SERVICE_URL} token 3 `,
            getToken()
          );
          window.location.assign(
            `${process.env.REACT_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}`
          );
          return;
        }
      };
