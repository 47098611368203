import { getToken } from './helpers';
import { FILE_UPLOAD_URL } from '../constants';
import { message } from 'antd';
import { alertSuccess } from '../../redux/middlewares/app/api';

class MyUploadAdapter {
  loader: Record<string, any>;
  // URL where to send files.
  url: string = FILE_UPLOAD_URL;
  xhr: any;
  UPLOAD_KEY = '@@RICH-TEXT-IMAGE-UPLOAD';

  constructor(props: Record<string, any>) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open('POST', this.url, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Authorization', getToken() as string);
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(
    resolve: (result?: any) => void,
    reject: (rejection?: any) => void
  ) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = 'Could not upload file:' + ` ${loader.file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      alertSuccess(`Image uploaded successfully!`, this.UPLOAD_KEY);
      resolve({
        default: response?.data?.url,
        response,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt: Record<string, any>) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
          message.loading({
            content: 'Uploading image',
            key: this.UPLOAD_KEY,
          });
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then((result: File) => {
      data.append('file', result);
      this.xhr.send(data);
    });
  }
}
export function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}
