import {createContext, ReactNode, ReactNodeArray} from 'react';
import {useAuth, useUIState} from '../../hooks';
import {PROVIDER_KEYS} from '../../_shared/constants';
import {getToken} from '../../_shared/utils';
import {RedirectAccount} from '../../components/_shared/RedirectAccount';


export type AuthProviderStateType = {
  auth: AuthNameSpace.User | undefined;
  getLoggedIn?: (user: any) => void;
};
const initialState: AuthProviderStateType = {
  auth: undefined,
};

interface AuthProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const token = getToken();
  const { auth, getLoggedIn } = useAuth({
    doFind: true,
    key: PROVIDER_KEYS.Auth,
  });

  const {
    uiLoaders: { [PROVIDER_KEYS.Auth]: loadingUser },
  } = useUIState();

  if ((!token || !auth || auth?.userType !== 'Student') && !loadingUser) {
    return <RedirectAccount userType={auth?.userType} />;
  }
  return (
    <AuthContext.Provider value={{ auth, getLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
