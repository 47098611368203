import {LOGOUT, RESET_APP_STATE} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import cookie from 'js-cookie';

export const logout: Middleware<unknown, RootState> = ({ dispatch }) => (
  next
) => (action) => {
  next(action);
  const { type } = action;
  if (type === LOGOUT) {
    if (cookie.get('token')) {
      cookie.remove('token');
      dispatch({ type: RESET_APP_STATE });
      // window.location.assign(
      //   `${process.env.REACT_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}` as string
      // );
    }
  }
};
